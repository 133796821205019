<script>
import { mapState } from 'vuex'

import { toastMixins, datetimeMixins } from '@/mixins'

import userService from '@/services/user/user'

import MenuBar from './components/MenuBar.vue'

export default {
  mixins: [toastMixins, datetimeMixins],
  components: {
    MenuBar
  },
  data() {
    return {
      sortBySelected: 'userId',
      sortByOptions: [
        { value: 'userId', text: 'รหัสผู้ใช้งาน' },
        { value: 'userLevel', text: 'ระดับผู้ใช้งาน' },
        { value: 'userName', text: 'ชื่อผู้ใช้งาน' },
        { value: 'department', text: 'แผนก' },
        { value: 'jobTitle', text: 'ตำแหน่งงาน' },
        { value: 'lastLoggedOnAt', text: 'เข้าสู่ระบบล่าสุดเมื่อ' },
        { value: 'lastPasswordChangedAt', text: 'รหัสเปลี่ยนล่าสุดเมื่อ' },
        { value: 'passwordExpiredAt', text: 'วันที่รหัสหมดอายุ' },
        { value: 'userExpiredAt', text: 'วันที่ผู้ใช้งานหมดอายุ' },
        { value: 'createdAt', text: 'สร้างเมื่อ' },
        { value: 'updatedAt', text: 'แก้ไขล่าสุดเมื่่อ' }
      ],
      sortTypeSelected: 'asc',
      sortTypeOptions: [
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' },
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' }
      ],
      searchBySelected: 'userId',
      searchByOptions: [
        { value: 'userId', text: 'รหัสผู้ใช้งาน' },
        { value: 'userLevel', text: 'ระดับผู้ใช้งาน' },
        { value: 'userName', text: 'ชื่อผู้ใช้งาน' },
        { value: 'department', text: 'แผนก' },
        { value: 'jobTitle', text: 'ตำแหน่งงาน' }
      ],
      searchKeyword: '',
      searchKeywordSetTimer: null,
      tableFields: [
        {
          field: 'userId',
          title: 'รหัสผู้ใช้งาน',
          align: 'center',
          width: 150
        },
        {
          field: 'userLevel',
          title: 'ระดับผู้ใช้งาน',
          align: 'center',
          width: 100
        },
        {
          field: 'userName',
          title: 'ชื่อผู้ใช้งาน',
          align: 'center',
          width: 200
        },
        {
          field: 'department',
          title: 'แผนก',
          align: 'center',
          width: 200
        },
        {
          field: 'jobTitle',
          title: 'ตำแหน่งงาน',
          align: 'center',
          width: 200
        },
        {
          field: 'lastLoggedOnAtFormat',
          title: 'เข้าสู่ระบบ',
          align: 'center',
          width: 150
        },
        {
          field: 'lastPasswordChangedAtFormat',
          title: 'รหัสเปลี่ยน',
          align: 'center',
          width: 150
        },
        {
          field: 'passwordExpiredAtFormat',
          title: 'รหัสหมดอายุ',
          align: 'center',
          width: 150
        },
        {
          field: 'userExpiredAtFormat',
          title: 'วันหมดอายุ',
          align: 'center',
          width: 150
        },
        {
          field: 'entryBy',
          title: 'แก้ไขโดย',
          align: 'center',
          width: 150
        },
        {
          field: 'createdAtFormat',
          title: 'สร้างเมื่อ',
          align: 'center',
          width: 150
        },
        {
          field: 'updatedAtFormat',
          title: 'ปรับปรุงเมื่อ',
          align: 'center',
          width: 150
        }
      ],
      userLogonMasterSelected: null,
      userLogonMasters: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultKeywordTimerMs: (state) => state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    sortBySelected() {
      this.fetchUserLogonMasters()
    },
    sortTypeSelected() {
      this.fetchUserLogonMasters()
    },
    searchBySelected() {
      this.fetchUserLogonMasters()
    },
    searchKeyword() {
      if (this.searchKeywordSetTimer) clearTimeout(this.searchKeywordSetTimer)

      this.searchKeywordSetTimer = setTimeout(() => {
        this.fetchUserLogonMasters()
      }, this.defaultKeywordTimerMs)
    }
  },
  async created() {
    await this.fetchUserLogonMasters()
  },
  methods: {
    async fetchUserLogonMasters() {
      this.userLogonMasterSelected = null
      try {
        const qsArray = []
        if (this.sortBySelected) qsArray.push(`sortBy=${this.sortBySelected}`)
        if (this.sortTypeSelected)
          qsArray.push(`sortType=${this.sortTypeSelected}`)
        if (this.searchBySelected)
          qsArray.push(`searchBy=${this.searchBySelected}`)
        if (this.searchKeyword && this.searchKeyword.trim().length > 0)
          qsArray.push(`keyword=${this.searchKeyword.trim()}`)
        const qs = qsArray.join('&')

        const res = await userService.getUserLogonMasters({ qs })
        this.userLogonMasters = this.formatResults(res.data.data)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    formatResults(users) {
      return users.map((user) => {
        const {
          lastLoggedOnAt,
          lastPasswordChangedAt,
          passwordExpiredAt,
          userExpiredAt,
          createdAt,
          updatedAt,
          ...restUser
        } = user

        return {
          ...restUser,
          lastLoggedOnAtFormat:
            this.mxConvertToDatetimeBuddhistFormat(lastLoggedOnAt),
          lastPasswordChangedAtFormat: this.mxConvertToDatetimeBuddhistFormat(
            lastPasswordChangedAt
          ),
          passwordExpiredAtFormat:
            this.mxConvertToDatetimeBuddhistFormat(passwordExpiredAt),
          userExpiredAtFormat:
            this.mxConvertToDatetimeBuddhistFormat(userExpiredAt),
          createdAtFormat: this.mxConvertToDatetimeBuddhistFormat(createdAt),
          updatedAtFormat: this.mxConvertToDatetimeBuddhistFormat(updatedAt)
        }
      })
    },
    getSearchKeywordPlaceholder(searchBySelected) {
      return (
        this.searchByOptions.find((option) => option.value === searchBySelected)
          .text || ''
      )
    },
    rowClickedHandler(selectedUserLogonMaster) {
      this.userLogonMasterSelected = selectedUserLogonMaster
    },
    rowDoubleClickedHandler(userLogonMaster) {
      this.$router.push(
        `/admin/user-logon-masters/detail/${userLogonMaster.userId}`
      )
    },
    getRowCss(row) {
      if (this.userLogonMasterSelected) {
        if (this.userLogonMasterSelected.id === row.id) {
          return { backgroundColor: 'lightblue' }
        }
      }
      return null
    },
    useStyles(key) {
      switch (key) {
        case 'datagrid-style':
          return { height: '500px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="ผู้ใช้งานทั้งหมด"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :userLogonMasterProp="userLogonMasterSelected"
          @updateTable="fetchUserLogonMasters"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- ส่วนของ selection & search -->
    <b-row class="my-3 d-flex justify-content-center">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="2">
            <label class="text-white text-sm" for="sort-by-selection">
              เรียงตาม
            </label>
            <b-form-select
              id="sort-by-selection"
              v-model="sortBySelected"
              :options="sortByOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="sort-type-selection">
              เรียงแบบ
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="sortTypeSelected"
              :options="sortTypeOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="2"></b-col>
          <b-col cols="12" md="2">
            <label class="text-white text-sm" for="sort-type-selection">
              ค้นหาโดย
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="searchBySelected"
              :options="searchByOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="search-input"> ค้นหา </label>
            <b-form-input
              id="search-input"
              v-model="searchKeyword"
              :placeholder="getSearchKeywordPlaceholder(this.searchBySelected)"
              style="font-size: 0.9rem"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ส่วนของ table -->
    <b-row class="d-flex flex-column align-items-center">
      <b-col cols="12" class="text-center">
        <div class="bg-light py-2">
          <h3>ผู้ใช้งานในระบบ</h3>
        </div>
      </b-col>
      <b-col cols="12" class="text-center">
        <DataGrid
          :data="userLogonMasters"
          :rowCss="getRowCss"
          :style="useStyles('datagrid-style')"
          @rowClick="rowClickedHandler"
          @rowDblClick="rowDoubleClickedHandler"
        >
          <GridColumn
            v-for="field in tableFields"
            :key="field.field"
            :field="field.field"
            :title="field.title"
            :align="field.align"
            :width="field.width"
          ></GridColumn>
        </DataGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
